<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!collecte">
      <span class="loader"></span>
    </span>
    <div v-if="collecte" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <collecte-view-global
            :collecte="collecte"
            @onValidateCollecte="validateCollecte"
            @onCollectCollecte="collectCollecte"
            @onDeliverCollecte="deliverCollecte"
            @onCancelCollecte="cancelCollecte"
            @onEditCollecte="editCollecte"
            @onDeleteCollecte="deleteCollecte"
          />
        </tab-pane>
        <tab-pane title="transaction" id="2" :active="true">
          <span slot="title">
            <i class="ni ni-money-coins"></i>
            {{ $t("COMMON.TRANSACTION") }}
          </span>
          <transaction-view-global :transaction="transaction" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import CollecteViewGlobal from "../../CollecteManagement/partials/CollecteViewGlobal.vue";
import TransactionViewGlobal from "../../TransactionManagement/partials/TransactionViewGlobal.vue";

export default {
  layout: "DashboardLayout",

  components: {
    CollecteViewGlobal,
    TransactionViewGlobal,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    CollecteViewGlobal,
  },

  mixins: [requestErrorMixin],

  props: {
    collecteId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      collecte: null,
      transaction: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("collectes/get", this.collecteId);
        this.collecte = this.$store.getters["collectes/collecte"];
        await this.$store.dispatch(
          "transactions/get",
          this.collecte.transaction.id
        );
        this.transaction = this.$store.getters["transactions/transaction"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    validateCollecte(collecte) {
      this.$emit("onValidateCollecte", collecte);
    },
    collectCollecte(collecte) {
      this.$emit("onCollectCollecte", collecte);
    },
    deliverCollecte(collecte) {
      this.$emit("onDeliverCollecte", collecte);
    },
    cancelCollecte(collecte) {
      this.$emit("onCancelCollecte", collecte);
    },
    editCollecte(collecte) {
      this.$emit("onEditCollecte", collecte);
    },
    deleteCollecte(collecte) {
      this.$emit("onDeleteCollecte", collecte);
    },
  },
};
</script>
