<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("COLLECTES.COLLECTES_LIST") }}</h3>
          </div>

          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openCollecteCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_COLLECTES)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">{{
                $t("COLLECTES.ADD_COLLECTE")
              }}</span>
            </base-button>

            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_COLLECTES)"
              :objectType="'collectes'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <collecte-list-table
        @onViewCollecte="openCollecteViewModal"
        @onEditCollecte="openCollecteEditModal"
        @onDeleteCollecte="deleteCollecte"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewCollecteModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewCollecteModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'COLLECTE_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("COLLECTES.VIEW_COLLECTE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openCollecte"
                :objectType="'collectes'"
                :objectId="openCollecte.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />

              <button class="close" @click="closeCollecteModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>

          <div class="resizable-wrapper-content-body">
            <view-collecte-component
              v-if="openCollecte"
              :collecteId="openCollecte.id"
              @onEditCollecte="openCollecteEditModal"
              @onDeleteCollecte="deleteCollecte"
              @onValidateCollecte="validateCollecte"
              @onCollectCollecte="collectCollecte"
              @onDeliverCollecte="deliverCollecte"
              @onCancelCollecte="cancelCollecte"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditCollecteModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditCollecteModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'COLLECTE'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("COLLECTES.EDIT_COLLECTE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="view"
                  @click="openCollecteViewModal(openCollecte)"
                >
                  <i class="fal fa-eye"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeCollecteModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-collecte-page
              v-if="openCollecte"
              :collecteId="openCollecte.id"
              @onViewCollecte="openCollecteViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddCollecteModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddCollecteModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'COLLECTE'"
        >
          <div class="resizable-wrapper-content-header">
            <h1>
              {{ $t("COLLECTES.ADD_COLLECTE") }}
            </h1>
            <button class="close" @click="closeCollecteModal">
              <i class="fal fa-times"></i>
            </button>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-collecte-page
              @onViewCollecte="openCollecteViewModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import CollecteListTable from "./partials/CollecteListTable.vue";
import EditCollectePage from "./components/EditCollecteComponent.vue";
import AddCollectePage from "./components/AddCollecteComponent.vue";
import ViewCollecteComponent from "./components/ViewCollecteComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    CollecteListTable,
    NotificationSubscription,
    EditCollectePage,
    AddCollectePage,
    ViewCollecteComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const collecteId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewCollecteModalOpened = false;
    let isEditCollecteModalOpened = false;
    let isAddCollecteModalOpened = false;
    let openCollecte = null;
    if (collecteId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewCollecteModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditCollecteModalOpened = true;
      }
      openCollecte = { id: collecteId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddCollecteModalOpened = true;
    }
    return {
      isViewCollecteModalOpened: isViewCollecteModalOpened,
      isEditCollecteModalOpened: isEditCollecteModalOpened,
      isAddCollecteModalOpened: isAddCollecteModalOpened,
      openCollecte: openCollecte,
      renderKey: 1,
    };
  },

  methods: {
    openCollecteCreateModal() {
      this.closeCollecteModal();
      this.isAddCollecteModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Collectes",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openCollecteViewModal(collecte, reRender = false) {
      this.closeCollecteModal();
      this.openCollecte = collecte;
      this.isViewCollecteModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Collectes",
          query: { id: this.openCollecte.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openCollecteEditModal(collecte) {
      this.closeCollecteModal();
      this.openCollecte = collecte;
      this.isEditCollecteModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Collectes",
          query: { id: this.openCollecte.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closeCollecteModal() {
      this.isAddCollecteModalOpened = false;
      this.isViewCollecteModalOpened = false;
      this.isEditCollecteModalOpened = false;
      this.openCollecte = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Collectes",
          query: {},
        }).href
      );
    },
    async validateCollecte(collecte) {
      this.loading = true;
      const confirmation = await swal.fire({
        text: this.$t("COLLECTES.VALIDATE_COLLECTE_QUESTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText: this.$t("COMMON.YES"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("collectes/validate", collecte.id);
          this.renderKey++;
          this.$notify({
            type: "success",
            message: this.$t("COLLECTES.COLLECTE_VALIDATED"),
          });
        }
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async collectCollecte(collecte) {
      this.loading = true;
      const confirmation = await swal.fire({
        text: this.$t("COLLECTES.COLLECT_COLLECTE_QUESTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText: this.$t("COMMON.YES"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("collectes/collect", collecte.id);
          this.renderKey++;
          this.$notify({
            type: "success",
            message: this.$t("COLLECTES.COLLECTE_COLLECTED"),
          });
        }
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async deliverCollecte(collecte) {
      this.loading = true;
      const confirmation = await swal.fire({
        text: this.$t("COLLECTES.DELIVER_COLLECTE_QUESTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText: this.$t("COMMON.YES"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("collectes/deliver", collecte.id);
          this.renderKey++;
          this.$notify({
            type: "success",
            message: this.$t("COLLECTES.COLLECTE_DELIVERED"),
          });
        }
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async cancelCollecte(collecte) {
      this.loading = true;
      const confirmation = await swal.fire({
        text: this.$t("COLLECTES.CANCEL_COLLECTE_QUESTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText: this.$t("COMMON.YES"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("collectes/cancel", collecte.id);
          this.renderKey++;
          this.$notify({
            type: "success",
            message: this.$t("COLLECTES.COLLECTE_CANCELED"),
          });
        }
        this.loading = false;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async deleteCollecte(collecte) {
      const confirmation = await swal.fire({
        text: this.$t("COLLECTES.DELETE_THIS_COLLECTE"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("collectes/destroy", collecte.id);
          this.renderKey++;
          this.closeCollecteModal();
          this.$notify({
            type: "success",
            message: this.$t("COLLECTES.COLLECTE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
