<template>
  <div class="container-fluid">
    <collecte-form
      :loading="loading"
      :collecteData="collecte"
      :formErrors="formErrors"
      @collecteSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import defaultTaxes from "@/constants/defaultTaxes";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultCollecte from "../defaultCollecte";
import CollecteForm from "../partials/CollecteForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    CollecteForm,
  },

  mixins: [alertLeave],
  props: [],

  data() {
    const collecte = cloneDeep(defaultCollecte);
    const me = this.$store.getters["profile/me"];
    return {
      collecte: collecte,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(collecte) {
      this.loading = true;

      const collecteData = cloneDeep(collecte);
      delete collecteData.id;

      try {
        await this.$store.dispatch("collectes/add", collecteData);
        this.$notify({
          type: "success",
          message: this.$t("COLLECTES.COLLECTE_ADDED"),
        });
        const collecte = this.$store.getters["collectes/collecte"];
        this.$emit("onViewCollecte", collecte, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
