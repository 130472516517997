<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div
      class="form-wrapper full"
    >
      <!-- Organization -->
      <base-input
        :label="`${$t('COMMON.TRANSACTION')} (*)`"
        :placeholder="$t('COMMON.TRANSACTION')"
      >
        <transaction-selector
          :transaction="collecte.transaction.id"
          :filterable="true"
          :solded="true"
          auction-status="completed"
          :showAll="false"
          @transactionChanged="
            (transactionId, transaction) => {
              collecte.transaction.id = transactionId;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.transaction" />
    </div>
    <div class="form-wrapper">
      <base-input
        :label="`${$t('COLLECTES.COLLECTED_LOCATION')}`"
        :placeholder="$t('COLLECTES.COLLECTED_LOCATION')"
        v-model="collecte.collect_location"
        @change="onFormChanged()"
      />
    </div>
    <div class="form-wrapper">
      <base-input
        :label="`${$t('COLLECTES.DELIVERED_LOCATION')}`"
        :placeholder="$t('COLLECTES.DELIVERED_LOCATION')"
        v-model="collecte.delivery_location"
        @change="onFormChanged()"
      />
    </div>
    <div class="form-wrapper">
      <base-input
        :label="`${$t('COLLECTES.CARRIER')}`"
        :placeholder="$t('COLLECTES.CARRIER')"
        v-model="collecte.carrier"
        @change="onFormChanged()"
      />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="$t('COLLECTES.EXCERPT')"
        :placeholder="$t('COLLECTES.EXCERPT')"
      >
        <html-editor v-model="collecte.excerpt" @change="onFormChanged()">
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{
          collecte.id
            ? $t("COLLECTES.EDIT_COLLECTE")
            : $t("COLLECTES.ADD_COLLECTE")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import Vue from "vue";
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import models from "@/constants/models";
import "flatpickr/dist/flatpickr.css";
import { COLLECTES_STATUS } from "@/constants/collectes";
import TransactionSelector from "@/components/TransactionSelector.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    HtmlEditor,
    ValidationError,
    TransactionSelector,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["collecteData", "formErrors", "loading"],

  data() {
    let collecteData = cloneDeep(this.collecteData);
    collecteData = this.$fillUserOrganizationData(collecteData);
    return {
      collecte: collecteData,
      COLLECTES_STATUS,
      permissions: [],
      permissionsModel: {},
      models: models,
    };
  },

  created() {},

  mounted() {},

  methods: {
    async handleSubmit() {
      let collecteData = cloneDeep(this.collecte);
      collecteData = this.$fillUserOrganizationData(collecteData);
      this.$emit("collecteSubmitted", collecteData);
    },
    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    collecteData(collecteData) {
      if (collecteData) {
        this.collecte = {
          ...this.collecte,
          ...cloneDeep(collecteData),
        };
      }
    },
  },
};
</script>
