export default {
  type: "collectes",
  collectedAt: null,
  deliveredAt: null,
  collectLocation: null,
  deliveryLocation: null,
  carrier: null,
  excerpt: null,
  relationshipNames: ["transaction"],
  transaction: {
    type: "transactions",
    id: null,
  },
};
